import React from 'react';
import PropTypes from 'prop-types';

import ActionDetails from '../ActionDetails';
import ActionProgressTimelineEntry from './ActionProgressTimelineEntry';

import { formatDateString } from 'services/date_helpers';

const ActionProgressHistory = ({ action }) => {
  const totalActions = [...action.previous_actions, action];
  const filteredActions = filterRepeatActions(totalActions);

  return <>
    <ActionDetails action={action} />
    <div className="actionProgressHistory">
      <div className="timeline">
        <div className="timelineStart">
          <div className="timelineLine"></div>
          <div className="details">
            Action created { formatDateString(getFirstAction(action).created_at) }
          </div>
        </div>
        { filteredActions.map((previousAction, i) => <ActionProgressTimelineEntry action={previousAction} key={`previous_action_${previousAction.id}`} latest={filteredActions.length - 1 === i} />) }
      </div>
    </div>
  </>
}

function getFirstAction(action) {
  return action.previous_actions.length > 0 ? action.previous_actions[0] : action;
}

function filterRepeatActions(actions) {
  return actions.filter((action, i) => {
    if (i === 0) {
      return true;
    }

    return actionsAreDifferent(action, actions[i-1]);
  });
}

function actionsAreDifferent(first, second) {
  if(
    first.description !== second.description ||
    first.deadline !== second.deadline ||
    first.assigned_to !== second.assigned_to ||
    first.completion_percentage !== second.completion_percentage
  ) {
    return true;
  } else {
    return false;
  }
}

ActionProgressHistory.propTypes = {
  action: PropTypes.shape({
    id: PropTypes.number,
    description: PropTypes.string.isRequired,
    assigned_to: PropTypes.string.isRequired,
    deadline: PropTypes.string.isRequired,
    completion_percentage: PropTypes.number.isRequired,
    created_at: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired,
    previous_actions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        description: PropTypes.string.isRequired,
        assigned_to: PropTypes.string.isRequired,
        deadline: PropTypes.string.isRequired,
        completion_percentage: PropTypes.number.isRequired,
        created_at: PropTypes.string.isRequired,
        updated_at: PropTypes.string.isRequired,
      })
    )
  }).isRequired
}

export default ActionProgressHistory;