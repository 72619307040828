import Masonry from 'masonry-layout';

export function masonrySetup() {
  if($('.masonry').length > 0){
    var grid = document.querySelector('.container.masonry > .row');
    return new Masonry(grid, {
      // options...
      itemSelector: '.col-md-4',
      columnWidth: '.col-md-4'
    });
  }
}
