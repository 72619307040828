import React from 'react';
import CompletedWorkloadTable from './CompletedWorkloadTable';
import CompletedWorkloadGraph from './CompletedWorkloadGraph';

class CompletedWorkload extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedStages: ['action_plan_feedback_given']
    }
  }

  handleStageClick = (stage) => {
    let { selectedStages } = this.state;

    if(selectedStages.includes(stage)) {
      selectedStages.splice(selectedStages.indexOf(stage), 1)
      this.setState({
        selectedStages
      })
    } else {
      selectedStages.push(stage);

      this.setState({ selectedStages })
    }
  }

  render() {
    const { aggregated_completed_workload, aggregated_workload } = this.props;
    const { selectedStages } = this.state;

    return (
      <React.Fragment>
        <h1>Completed Workload</h1>
        <CompletedWorkloadGraph data={aggregated_completed_workload} workload_data={aggregated_workload} selectedStages={selectedStages} />
        <CompletedWorkloadTable data={aggregated_completed_workload} workload_data={aggregated_workload} selectedStages={selectedStages} handleStageClick={this.handleStageClick}/>
      </React.Fragment>
    )
  }
}

export default CompletedWorkload;