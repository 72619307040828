import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import { openModal } from 'actions/reports/workload';
import { completed_workload_stage_names } from 'services/workload_report_helper'

function getQueryVariable(key)
{
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i=0;i<vars.length;i++) {
    var pair = vars[i].split("=");
    if(pair[0] == key){return pair[1];}
  }
  return(false);
}

const CompletedWorkloadTable = ({ data, workload_data, selectedStages, handleStageClick }) =>
  <div className="completed-workload-table workload-report-table">
    <table className="table">
      <CompletedWorkloadTableHeaderRow data={data} />
      <tbody>
        { Object.keys(completed_workload_stage_names).map((stage) => 
          <ConnectedCompletedWorkloadTableRow
            key={`completed_workload_table_${stage}`}
            data={data}
            workload_data={workload_data}
            stage={stage}
            selected={selectedStages.includes(stage)}
            handleStageClick={() => handleStageClick(stage)} />
        )}
      </tbody>
    </table>
  </div>

const CompletedWorkloadTableHeaderRow = ({ data }) =>
  <thead>
    <tr>
      <th>Event</th>
      { Object.entries(data).map(([key, dataRow]) =>
        <th key={`completed_workload_table_header_${key}`}>{ dataRow.date }</th>
      ) }
    </tr>
  </thead>

const CompletedWorkloadTableRow = ({ data, stage, workload_data, selected, handleStageClick, handleOpenModal }) => {
  return (
    <tr className={`completed-workload-table-row ${selected ? 'selected' : ''}`}>
      <td style={{ borderLeft: `8px solid ${completed_workload_stage_names[stage].color}`}} onClick={handleStageClick}>
        { completed_workload_stage_names[stage].name }
        { completed_workload_stage_names[stage].help_text &&
          <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={
              <Popover title={completed_workload_stage_names[stage].name} id={`popover-${stage}`}>{ completed_workload_stage_names[stage].help_text }</Popover>
            }>
            <i className="fas fa-question-circle help-text-icon" />
          </OverlayTrigger>
        }
      </td>
      { Object.entries(data).map(([index, dataRow]) =>
        <td key={`completed_workload_table_row_${stage}_${index}`}>
          <span className="completed-workload-table-count"
            onClick={() => handleOpenModal(mergeCompletedAndPotentialWorkloadCompanies(dataRow[stage].company_record_ids, workload_data, completed_workload_stage_names[stage].workload_key, index, stage), `Completed workload for ${completed_workload_stage_names[stage].name} - ${dataRow.date}`, 'completed_workload', stage)}>
            { getQueryVariable('debug') != 'true' && dataRow[stage].count }
            { getQueryVariable('debug') == 'true' && dataRow[stage].company_record_ids.map((company_record_id) =>
              <span key={`${Math.random()}_process_timescale_cr_link_${stage['name']}_${company_record_id}`}>
              <a href={`/staff/company_records/${company_record_id}`}>
                {company_record_id}
              </a>
              <br></br>
              </span>
            )
            }
          </span>
        </td>
      )}
    </tr>
  )
}

function mergeCompletedAndPotentialWorkloadCompanies(completed_ids, workload_data, workload_key, index, stage) {
  const potential_workload_ids = workload_key ? workload_data[index][completed_workload_stage_names[stage].workload_key].company_record_ids : []

  return {
    completed_ids,
    potential_workload_ids 
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    handleOpenModal: openModal
  }, dispatch);
};

const ConnectedCompletedWorkloadTableRow = connect(null, mapDispatchToProps)(CompletedWorkloadTableRow);

export default CompletedWorkloadTable;