import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import { openModal } from 'actions/reports/workload';
import { current_workload_stage_names } from 'services/workload_report_helper';

const CurrentWorkloadTable = ({ data, handleOpenModal }) =>
  <div className="current-workload-table workload-report-table">
    <h1 className="padding-top-10 padding-left-10">Current Workload (Live)</h1>
    <small className="padding-left-10 text-muted">Please note this table displays results without hibernated and removed companies.</small>
    <table className="table">
      <CurrentWorkloadTableHeaderRow data={data} />
      <tbody>
        { Object.keys(current_workload_stage_names).map((stage) =>
          <CurrentWorkloadTableRow
            key={`current_workload_table_${stage}`}
            data={data}
            stage={stage}
            handleOpenModal={handleOpenModal} />
        )}
      </tbody>
    </table>
  </div>

const CurrentWorkloadTableHeaderRow = ({ data }) =>
  <thead>
    <tr>
      <th></th>
      { Object.values(data).map((dataRow) =>
        <th key={`current_workload_table_header_${dataRow.name}`}>{ dataRow.name }</th>
      ) }
    </tr>
  </thead>

const CurrentWorkloadTableRow = ({ data, stage, handleOpenModal }) =>
  <tr className={stage}>
    <td>
      { current_workload_stage_names[stage].name }
      { current_workload_stage_names[stage].help_text &&
          <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={
              <Popover title={current_workload_stage_names[stage].name} id={`popover-${stage}`}>{ current_workload_stage_names[stage].help_text }</Popover>
            }>
            <i className="fas fa-question-circle help-text-icon" />
          </OverlayTrigger>
        }
    </td>
    { Object.values(data).map((dataRow, index) =>
      <td key={`current_workload_table_row_${stage}_${index}`}>
        <span className="current-workload-count" onClick={() => handleOpenModal(dataRow.data[stage].company_record_ids, `Current workload for ${dataRow.name} - ${current_workload_stage_names[stage].name}`, 'current_workload', stage)}>
          { dataRow.data[stage].count }
        </span>
      </td>
    )}
  </tr>

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    handleOpenModal: openModal
  }, dispatch);
};

export default connect(null, mapDispatchToProps)(CurrentWorkloadTable)