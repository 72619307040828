import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import { seconds_to_business_time, seconds_in_day } from 'services/date_helpers';
import { process_totals_stages } from 'services/workload_report_helper';

function getQueryVariable(key)
{
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i=0;i<vars.length;i++) {
    var pair = vars[i].split("=");
    if(pair[0] == key){return pair[1];}
  }
  return(false);
}

const ProcessTotalsTable = ({ aggregated_time_taken, report_type, aggregated_all_time_taken, show_process_average, show_kpis, kpi_times }) =>
  <div className="process-totals-table workload-report-table">
    <table className="table">
      <thead>
        <tr>
          <th>Stage</th>
          <th>Avg. time to complete</th>
          { show_process_average && <th>Process Average</th> }
          <th>Number of occurrences</th>
          { show_kpis && <th>KPIs</th> }
        </tr>
      </thead>
      <tbody>
        { Object.keys(process_totals_stages).map((stage) => 
          <ProcessTotalsTableRow
            key={`process_totals_table_${stage}`}
            data={aggregated_time_taken[stage][report_type]}
            process_data={show_process_average && aggregated_all_time_taken[stage][report_type]}
            stage={process_totals_stages[stage]}
            show_process_average={show_process_average}
            show_kpis={show_kpis}
            kpi_times={kpi_times} />
        )}
      </tbody>
    </table>
  </div>
// data.company_record_ids.map(x => "<a href='/staff/company_records/" + x +  "'>" + x + "</a>").join(', ') }
const ProcessTotalsTableRow = ({ data, stage, show_process_average, show_kpis, process_data, kpi_times }) =>
  <tr>
    <td>
      { stage.name }
      <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={<Popover title={stage.name} id={`popover-${stage}`}>{ stage.help_text }</Popover>}><i className="fas fa-question-circle help-text-icon" /></OverlayTrigger>
    </td>
    <td>{ seconds_to_business_time(data.average) }</td>
    { show_process_average &&<td>{ seconds_to_business_time(process_data.average) }</td> }
    <td>{ data.durations.length }</td>
    <td>
      { getQueryVariable('debug') == 'true' && data.company_record_ids.map((company_record_id) =>
        <span key={`${Math.random()}_process_timescale_cr_link_${stage['name']}_${company_record_id}`}>
        <a href={`/staff/company_records/${company_record_id}`}>
          {company_record_id}
        </a>
        <br></br>
        </span>
      )
      }
    </td>
    { show_kpis && stage.kpi_able &&
      <td>
        <div className="kpis">
          <div className="kpi kpi-normal">{ countKpiCompanies(data.durations, 0, kpi_times[stage.kpi_key].kpi_normal * seconds_in_day) }</div>
          <div className="kpi kpi-slow">{ countKpiCompanies(data.durations, kpi_times[stage.kpi_key].kpi_normal * seconds_in_day, kpi_times[stage.kpi_key].kpi_slow * seconds_in_day) }</div>
          <div className="kpi kpi-overdue">{ countKpiCompanies(data.durations, kpi_times[stage.kpi_key].kpi_slow * seconds_in_day, 600000000) }</div>
        </div>
      </td>
    }
    { show_kpis && !stage.kpi_able && <td></td> }
  </tr>


function countKpiCompanies(companyTimes, startTime, endTime) {
  return companyTimes.filter((companyTime) => (companyTime > startTime && companyTime <= endTime)).length
}

export default ProcessTotalsTable;