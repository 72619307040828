import React from 'react';
import { Button, ControlLabel, FormControl, FormGroup } from 'react-bootstrap';

import Icon from 'components/Icon';

const SupportingEvidence = ({ documents, handleFileAdditions, handleFileRemove }) => {
  return (
    <div className="supportingEvidence">
      <h3>Supporting Evidence</h3>
      {
        documents && documents.map((document, index) => document._destroy ? null : <Document document={document} key={`document_${document.id}`} handleFileRemove={() => handleFileRemove(index)} />)
      }
      <FormGroup
        controlId="document"
        className="margin-bottom-5 documentInput">
        <ControlLabel>
            Add a new document <small>Click here or drag and drop files</small>
        </ControlLabel>
        <FormControl
          name="document"
          type="file"
          multiple={true}
          onChange={(e) => handleFileAdditions(e.target.files)} />
      </FormGroup>
    </div>
  )
}

const Document = ({ document, handleFileRemove }) => {
  return (
    <div className="document">
      <div className="documentName">
        { document.action_document.file.url !== undefined &&
          <a href={`/customer/action_documents/${document.action_document.id}`}>{ document.action_document.file.url.split('/').pop() }</a>
        }
        { document.new &&
          <p>{ document.action_document.file.name }</p>
        }
      </div>
      <div className="documentActions">
        <Button bsStyle="danger" onClick={handleFileRemove}><Icon icon="trash" /> Remove</Button>
      </div>
    </div>
  );
}

export default SupportingEvidence;