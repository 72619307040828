import React from 'react';
import Icon from 'components/Icon';

import { formatDateString } from 'services/date_helpers';

const ActionProgressTimelineEntry = ({ action, latest=false }) =>
  <div className={`timelineEntry ${latest ? 'latest' : ''}`}>
    <div className="timelineGraphic">
      <div className="timelineLine"></div>
      <div className="timelineMarker"></div>
    </div>
    <div className="detailsWrap">
      <div className="details">
        <div className="detailsHeader">
          <div className="percentage">{ action.completion_percentage }% complete</div>
          <div className="time">Updated on { formatDateString(action.updated_at) }</div>
        </div>
        <div className="description">{ action.comment || action.progress }</div>
        { action.document && 
          <div className="document"><a href={`/customer/documents/${action.document.id}`}><Icon icon="file" style="far" /> Download document</a></div>
        }
      </div>
    </div>
  </div>

export default ActionProgressTimelineEntry;