import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { actionPlanProgressCount, validateTopic } from 'services/action_plan_helper';
import { changeTopic } from 'actions/action_plan/initial';

import SavingSpinner from './SavingSpinner';

const ActionPlanProgress = () => {
  const dispatch = useDispatch();

  const topics = useSelector((state) => state.topics);
  const currentTopicId = useSelector((state) => state.form.currentTopicGoal.id);
  const selectedIndex = useSelector((state) => state.form.selectedIndex);
  const previousTopic = topics[selectedIndex - 1];
  const nextTopic =     topics[selectedIndex + 1];
  const saving =        useSelector((state) => state.form.saving);
  const changed =       useSelector((state) => state.form.changed);

  return (
    <div className="actionPlanProgress">
      <SavingSpinner saving={saving} />
      <div className="actionPlanProgressInner">
        <span className="count">Completed { actionPlanProgressCount(topics) }</span>
        <div className="progressView">
          { topics.map((topic, index) =>
            <ActionPlanProgressTopic key={`topic_${topic.id}`} topic={topic} handleTopicChange={() => dispatch(changeTopic(topic, index))} currentTopic={currentTopicId === topic.id} />
          )}
        </div>
        <div className="actionPlanProgressButtons">
          <div className="previous">{ previousTopic && <div className="action previous">{ previousTopic && <button className="btn btn-default" onClick={() => dispatch(changeTopic(previousTopic, selectedIndex - 1))}><i className="fas fa-fw fa-arrow-left" />{ changed ? 'Save and previous action' : 'Previous action' }</button> }</div> }</div>
          <div className="key">
            <div className="complete">Complete</div>
            <div className="incomplete">Incomplete</div>
            <div className="unviewed">Unviewed</div>
          </div>
          <div className="next">{ nextTopic && <div className="action next">{ nextTopic && <button className="btn btn-default" onClick={() => dispatch(changeTopic(nextTopic, selectedIndex + 1))}>{ changed ? 'Save and next topic' : 'Next topic' }<i className="fas fa-fw fa-arrow-right" /></button> }</div> }</div>
        </div>
      </div>
    </div>
  )
}

const ActionPlanProgressTopic = ({ topic, handleTopicChange, currentTopic }) => {
  let topicClass = `topic topic_${topic.assessment_topic.id} `;
  
  if(topic.assessment_option_id !== null || topic.no_action) {
    if(validateTopic(topic)) {
      topicClass += 'complete';
    } else {
      topicClass += 'incomplete';
    }
  } else {
    topicClass += 'unviewed';
  }

  if(currentTopic) {
    topicClass += ' current';
  }

  return (
    <div className={topicClass} onClick={handleTopicChange}>
      <span className="topicName">{ topic.assessment_topic.name }</span>
    </div>
  )
}

export default ActionPlanProgress;