import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { selectAssessmentGoal } from 'actions/action_plan/initial';

const ActionPlanStandardSelection = ({ editable=true }) => {
  const dispatch = useDispatch();
  const currentTopicGoal = useSelector((state) => state.form.currentTopicGoal);
  // currentTopicGoal.on_site_score = 0;
  const options = useSelector((state) => state.form.currentTopicGoal.assessment_topic.assessment_options.filter((option) => option.score >= currentTopicGoal.on_site_score && !(currentTopicGoal.on_site_score === 0 && option.score === 2)));
  const selectedOptionId = useSelector((state) => state.form.selectedOption?.id || state.form.currentTopicGoal.no_action && options[0].id);
  const selectedOption = options.find(option => option.id === selectedOptionId);

  const handleStandardSelection = (optionId) => {
    if (editable) {
      dispatch(selectAssessmentGoal(currentTopicGoal.id, optionId))
    }
  }

  return (
    <div className="actionPlanStandardSelection">
      <div className="text-center margin-bottom-5"><strong>Select the standard you wish to aim for.</strong></div>
      <div className="options">
        {
          options && options.map((option, index) =>
            <button className={`option ${ option.id === selectedOptionId ? 'selected' : ''}`} key={ option.identifier } onClick={() => handleStandardSelection(option.id)}>
              { index === 0 && <strong>Maintain on-site score</strong> }
              { index > 0 && <strong>Improve to score</strong> }
              <div className="optionBody">
                <div className="score">
                  { option.score }
                </div>
                <div className="description">
                  <p>{ option.description }</p>
                </div>
              </div>
            </button>
          )
        }
      </div>
      {
        selectedOption && selectedOption.score > currentTopicGoal.on_site_score &&
        <div className="suggestion">
          <h3>To achieve the selected standard we would suggest...</h3>
          <blockquote>{ selectedOption.goal_feedback }</blockquote>
        </div>
      }
    </div>
  )
}

export default ActionPlanStandardSelection;