import React, { useState } from 'react';
import { OverlayTrigger, Popover, Collapse, Button } from 'react-bootstrap';

import { seconds_to_business_time, seconds_in_day, seconds_in_week } from 'services/date_helpers';
import { process_totals_stages } from 'services/workload_report_helper';

const keys_for_diagram = [
  "time_to_capability_questionnaire_complete",
  "time_to_self_diagnostic_complete",
  "time_to_on_sites_booked",
  "time_to_on_sites_attended",
  "time_to_first_on_site_draft_submitted",
  "time_to_on_site_drafts_accepted",
  "time_to_first_action_plan_submission",
  "time_to_recommended_for_granting_after_initial_action_plan",
  "time_to_f4n_granting_after_recommendation"
]

const ProcessTotalsGraph = ({ aggregated_time_taken, report_type, aggregated_all_time_taken, show_process_average, show_kpis, kpi_times }) => {
  let longestTime = 0;
  if(show_process_average) {
    longestTime = longest_time([...map90thPercentile(aggregated_time_taken, report_type), ...map90thPercentile(aggregated_all_time_taken, report_type)])
  } else {
    longestTime = longest_time(map90thPercentile(aggregated_time_taken, report_type))
  }

  const [open, setOpen] = useState(true);

  return(
    <div className='process-totals-graph-wrapper'>
      <Button bsSize="small" className="hide-graph-button" onClick={() => setOpen(!open)}>{ open && 'Hide'}{ !open && 'Show'} graph</Button>
      <Collapse in={open}>
        <div className="process-totals-graph">
          <div className="initial-chart-row">
            <div className="stage-cell"><span>Company Signs Up</span></div>
            <div className="stage-spacer"></div>
            <div className="time-cell"></div>
          </div>

          { keys_for_diagram.map(key => 
            <ProcessTotalsRow
              key={`process_totals_${key}`}
              stage={key}
              data={aggregated_time_taken[key][report_type]}
              allTimeData={show_process_average && aggregated_all_time_taken[key][report_type]}
              longestTime={longestTime}
              kpiTimes={kpi_times}
              showKpis={show_kpis} />
          ) }
        </div>
      </Collapse>
    </div>
  )
}

const ProcessTotalsRow = ({ stage, data, allTimeData, longestTime, dashed=false, showKpis, kpiTimes }) => {
  if(data.average === null || data.average === undefined) {
    return <ProcessTotalsRowInvalid stage={stage} dashed={dashed} />
  } else {
    const stageDetails = process_totals_stages[stage];
    if(!stageDetails.to_scale) {
      longestTime = data.max
    }
    // to_scale defines whether the row is in a scale with the other rows.
    // By not having it as part of this global scale, it will instead display as its own scale
    // const tenthpercentilePosition = stageDetails.to_scale ? ((data.min / longestTime) * 100) : ((data.min / data.max) * 100);
    const tenthpercentilePosition = stageDetails.to_scale ? ((data.percentile_10th / longestTime) * 100) : ((data.percentile_10th / data.max) * 100);
    const averagePosition = stageDetails.to_scale ? ((data.average / longestTime) * 100) : ((data.average / data.max) * 100)
    const percentilePosition = stageDetails.to_scale ? ((data.percentile_90th / longestTime) * 100) : ((data.percentile_90th / data.max) * 100);

    return (
      <div className="chart-row">
        <div className="stage-cell">
          <span>
            {stageDetails.name}
            <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={<Popover title={stageDetails.name} id={`popover-${stage}`}>{ stageDetails.help_text }</Popover>}><i className="fas fa-question-circle help-text-icon" /></OverlayTrigger>
          </span>
        </div>
        <div className={`stage-spacer ${ dashed ? 'dashed' : '' }`}></div>
        <div className={`time-cell ${allTimeData ? 'showing-average' : ''}`}>
          { stageDetails.kpi_able && showKpis &&
            <KpiBackground kpiTimes={kpiTimes[stageDetails.kpi_key]} longestTime={longestTime} />
          }
          { (!showKpis || !stageDetails.kpi_able) &&
            <div className={`scale-background ${stageDetails.to_scale ? 'global-scale' : 'local-scale' }`} style={{ left: `${stageDetails.to_scale ? tenthpercentilePosition : 0}%`, width: `${stageDetails.to_scale ? (percentilePosition - tenthpercentilePosition) : 100}%` }}></div>
          }
          { data.count >= 2 && 
            <React.Fragment>
              <ProcessTotalsMarker className="min-marker" position={tenthpercentilePosition} title="10th Percentile" value={data.percentile_10th} />
              <ProcessTotalsMarker className="percentile-marker" position={percentilePosition} title="90th percentile" value={data.percentile_90th} />
              <div className="measure process-total" style={{ left: `${tenthpercentilePosition}%`, width: `${percentilePosition - tenthpercentilePosition}%` }}></div>
            </React.Fragment>
          }
          <ProcessTotalsMarker className="average-marker" position={averagePosition} title="Average" value={data.average} />
          { allTimeData &&
            <ProcessAverageMarkers allTimeData={allTimeData} longestTime={longestTime} stageDetails={stageDetails} />
          }
          <RowScale numberOfWeeks={longestTimeToWeeks(longestTime)} longestTime={longestTime} stage={stage} />
        </div>
      </div>
    );
  }
}

const RowScale = ({ numberOfWeeks, longestTime, stage }) => {
  const weekScale = mostAppropiateWeekGap(numberOfWeeks);

  return (
    <div className="scale">
      { [...Array(Math.floor(numberOfWeeks/weekScale))].map((e, i) =>
        <div className="scaleTimeEntry" key={`scale_time_entry_${stage}_${i}`} style={{ left: `${(((i * weekScale) * seconds_in_week) / longestTime) * 100}%`, width: `${(weekScale/numberOfWeeks) * 100}%` }}>
          <span>{ (i * weekScale) + weekScale } wks</span>
        </div>
      )}
    </div>
  )
}

function longestTimeToWeeks(longestTime) {
  if(longestTime > seconds_in_week) {
    return Math.floor(longestTime / seconds_in_week)
  } else {
    return 1
  }
}

function mostAppropiateWeekGap(numberOfWeeks) {
  return Math.ceil(numberOfWeeks/16)
}

const KpiBackground = ({ kpiTimes, longestTime }) => {
  const kpi_normal_width = kpiTimes.kpi_normal * seconds_in_day
  const kpi_slow_width = (kpiTimes.kpi_slow * seconds_in_day) - kpi_normal_width
  const kpi_overdue_width = longestTime - kpi_normal_width - kpi_slow_width

  return (
    <div className="kpi-background">
      <div className="kpi-normal" style={{ flex: kpi_normal_width, flexGrow: kpi_normal_width }} />
      <div className="kpi-slow" style={{ flex: kpi_slow_width, flexGrow: kpi_slow_width }} />
      <div className="kpi-overdue" style={{ flex: kpi_overdue_width, flexGrow: kpi_overdue_width }} />
    </div>
  )
}

const ProcessAverageMarkers = ({ allTimeData, longestTime, stageDetails }) => {
  const tenthpercentilePosition = stageDetails.to_scale ? ((allTimeData.percentile_10th / longestTime) * 100) : ((allTimeData.percentile_10th / allTimeData.max) * 100);
  const averagePosition = stageDetails.to_scale ? ((allTimeData.average / longestTime) * 100) : ((allTimeData.average / allTimeData.max) * 100)
  const percentilePosition = stageDetails.to_scale ? ((allTimeData.percentile_90th / longestTime) * 100) : ((allTimeData.percentile_90th / allTimeData.max) * 100);

  return (
    <React.Fragment>
      <div className="measure process-total" style={{ left: `${tenthpercentilePosition}%`, width: `${percentilePosition - tenthpercentilePosition}%` }} />
      <ProcessTotalsMarker className="process-average-marker process-average-marker-percentile" position={percentilePosition} title="Process 90th percentile" value={allTimeData.percentile_90th} />
      <ProcessTotalsMarker className="process-average-marker process-average-marker-average" position={averagePosition} title="Process average" value={allTimeData.average} />
      <ProcessTotalsMarker className="process-average-marker process-average-marker-min" position={tenthpercentilePosition} title="Process 10th percentile" value={allTimeData.percentile_10th} />
    </React.Fragment>
  )
}

const ProcessTotalsMarker = ({ position, title, value, className }) =>
  <div className={`marker ${className}`} style={{ left: `${position}%` }}>
    <div className="markerShape"></div>
    <span className="process-pop-over">
      <strong>{ title }</strong><br />
      { seconds_to_business_time(value) }
    </span>
  </div>

const ProcessTotalsRowInvalid = ({ stage, dashed }) =>
  <div className="chart-row invalid">
    <div className="stage-cell">
      <span>{process_totals_stages[stage].name}</span>
    </div>
    <div className={`stage-spacer ${ dashed ? 'dashed' : '' }`}></div>
    <div className="time-cell">
      <div className="time-block" style={{ width: '40%' }}>
        <span>No data</span>
      </div>
    </div>
  </div>

function longest_time(data) {
  return Math.max(...data);
}

function map90thPercentile(data, assessment_type) {
  return filterNotToScaleKeys(data).map(allowedKey => {
    return data[allowedKey][assessment_type].percentile_90th
  }).flat()
}

function filterNotToScaleKeys(data) {
  return Object.keys(data)
    .filter(key => process_totals_stages[key].to_scale)
}

export default ProcessTotalsGraph;