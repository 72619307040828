import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import { stalled_companies_stage_names } from 'services/workload_report_helper';
import { openModal } from 'actions/reports/workload';

const StalledCompaniesTable = ({ data, handleOpenModal }) =>
  <div className="workload-report-table stalled-companies-table">
    <h1 className="padding-top-10 padding-left-10">Stalled Companies</h1>
    <table className="table">
      <StalledCompaniesTableHeaderRow data={data} />
      <tbody>
        { Object.keys(stalled_companies_stage_names).map((stage) => 
          <StalledCompaniesTableRow
            key={`stalled_companies_table_${stage}`}
            data={data}
            stage={stage}
            handleOnClick={handleOpenModal} />
        )}
      </tbody>
    </table>
  </div>

const StalledCompaniesTableHeaderRow = ({ data }) =>
  <thead>
    <tr>
      <th></th>
      { Object.entries(data).map(([key, dataRow]) =>
        <th key={`stalled_companies_table_header_${key}`}>{ dataRow.date }</th>
      ) }
    </tr>
  </thead>

const StalledCompaniesTableRow = ({ data, stage, handleOnClick }) =>
  <tr>
    <td>
      { stalled_companies_stage_names[stage].name }
      <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={
          <Popover title={stalled_companies_stage_names[stage].name} id={`popover-${stage}`}>{ stalled_companies_stage_names[stage].help_text }</Popover>
        }>
        <i className="fas fa-question-circle help-text-icon" />
      </OverlayTrigger>
    </td>
    { Object.entries(data).map(([index, dataRow]) =>
      <StalledCompaniesTableCell key={`stalled_companies_table_row_${stage}_${index}`} count={dataRow[stage].count} handleOnClick={() => handleOnClick(dataRow[stage].company_record_ids, `Stalled companies: ${stalled_companies_stage_names[stage].name} - ${dataRow.date}`, 'stalled_companies', stage)} previousCount={getPreviousCount(data, index, stage)} />
    )}
  </tr>

const StalledCompaniesTableCell = ({ count, previousCount, handleOnClick }) => {
  return (
    <td className="stalled-companies-cell">
      <span onClick={handleOnClick}>
        <i className={`stalled-company-icon fas fa-fw fa-${stalledCompaniesCellIcon(count, previousCount)}`} />
        { count }
      </span>
    </td>
  )
}

function stalledCompaniesCellIcon(count, previousCount) {
  if(count === previousCount) {
    return 'circle';
  } else if(count > previousCount) {
    return 'chevron-up';
  } else if(count < previousCount) {
    return 'chevron-down';
  } else if(previousCount === undefined) {
    return 'horizontal-rule'
  }
}

function getPreviousCount(data, index, stage) {
  if(data[index - 1] !== undefined) {
    return data[index - 1][stage].count;
  } else {
    return undefined;
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    handleOpenModal: openModal
  }, dispatch);
};

export default connect(null, mapDispatchToProps)(StalledCompaniesTable)