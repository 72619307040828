import React from 'react';
import { Provider } from 'react-redux';
import { combineReducers, createStore, applyMiddleware } from 'redux';

import { WorkloadReducer } from 'reducers/reports/workload';
import { getCurrentWorkloadCompanyRecords, getCompletedWorkloadCompanyRecords } from 'middleware/reports/workload';

import SentryErrorBoundary from 'components/SentryErrorBoundary';
import ProcessTotalsSection from './ProcessTotalsSection';
import CompletedWorkloadSection from './CompletedWorkloadSection';
import CurrentWorkloadSection from './CurrentWorkloadSection';
import StalledCompaniesSection from './StalledCompaniesSection';
import CompaniesModal from './CompaniesModal';

const WorkloadReportPage = ({ aggregated_time_taken, aggregated_all_time_taken, aggregated_completed_workload, aggregated_workload, aggregated_stalled_companies, ia_current_workload, industrial_advisor, kpi_times }) => {
  const store = createStore(
    combineReducers({ workload: WorkloadReducer }),
    applyMiddleware(getCurrentWorkloadCompanyRecords, getCompletedWorkloadCompanyRecords)
  );

  const ia_selected = industrial_advisor ? true : false

  return (
    <SentryErrorBoundary>
      <Provider store={store}>
        <div className="workload-report-wrapper">
          <ProcessTotalsSection aggregated_time_taken={aggregated_time_taken} aggregated_all_time_taken={aggregated_all_time_taken} ia_selected={ia_selected} kpi_times={kpi_times} />
          <CompletedWorkloadSection aggregated_completed_workload={aggregated_completed_workload} aggregated_workload={aggregated_workload} />
          <CurrentWorkloadSection data={ia_current_workload} />
          <StalledCompaniesSection data={aggregated_stalled_companies} />
          <CompaniesModal />
        </div>
      </Provider>
    </SentryErrorBoundary>
  )
}


export default WorkloadReportPage;