import { loadCompaniesSuccess, loadCompaniesError } from 'actions/reports/workload';
import { requestCompaniesDetails } from 'services/api/reports/workload.api';

export const getCurrentWorkloadCompanyRecords = ({ dispatch, getState }) => next => action => {
  if(action.type !== "OPEN_MODAL" || (action.section !== 'current_workload' && action.section !== 'stalled_companies')) {
    return next(action)
  } else if(action.company_record_ids.length === 0) {
    return null
  }

  const company_record_ids = action.company_record_ids;

  requestCompaniesDetails(company_record_ids)
    .then(res => res.json())
    .then(res => dispatch(loadCompaniesSuccess(res)))
    .catch(err => dispatch(loadCompaniesError(err)));
  
  next(action);
};
